<template>
    <exam-results-component></exam-results-component>
</template>

<script>
import ExamResultsComponent from "./ExamResultsComponent";
export default {
  data() {
    return {}
  },
  components: {
    ExamResultsComponent
  }
};

</script>

<style scoped>
</style>
